/* General Styling */
.about-us{
  background-color: #f4f4f4;
}

/* Hero Section */
.about-hero {
  background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../assets/aboutpage.avif');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  color: white;
}

.about-hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: "Righteous" ,sans-serif;
}

.about-hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Responsive Hero */
@media (max-width: 768px) {
  .about-hero {
    height: 50vh;
    width: 100%;
  }
  .about-hero h1 {
    font-size: 2.5rem;
  }
  .about-hero p {
    font-size: 1rem;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  
  
  .about-hero {
    height: 40vh;
  }
  .about-hero h1 {
    font-size: 2rem;
  }
  .about-hero p {
    font-size: 0.9rem;
  }
}

/* About Container with Two Columns */
.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  align-items: center;
  padding:  40px 20px;
}


.about-right,.about-left {
  flex: 1;
  max-width: 100%;
}
 .about-left{
  padding: 10px;
  
 }

.about-image {
  width: 600px;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
  
}

/* Tablet view (768px and below) */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .about-right,
  .about-left {
    width: 100%;
    
  }
  .about-right {
    order: 2; /* Ensure image comes after text */
  }

  .about-left {
    order: 1;
  }

  .about-image {
    width: 100%;
    max-width: 550px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .about-container {
    padding: 20px 10px;
    gap: 20px;
  }

  .about-left,
  .about-right {
    width: 100%;
    text-align: center;
  }

  .about-right {
    order: 2;
    
  }

  .about-left {
    order: 1;
    text-align: start;
  }

  .about-image {
    width: 100%;
    max-width: 350px;
    height: 300px;
    margin-bottom: 10px;
  }
}

/* About Cards Section */
.about-cards {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  background: #2B4E84;
  padding: 40px;
}

.about-card {
  flex: 1;
  max-width: 300px;
  background-color: #ffffff;
  padding: 20px;
  
 
  transition: transform 0.3s ease;
  border-radius: 15px;
  text-align: center;
}

.about-card img {
  width: 260px;
  height: 260px;
  /* border-radius: 10px; */
}
.about-card h2{
  color:#2B4E84;
  padding: 10px;
}
.about-card p{
  margin: 10px;
}
.about-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.5);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-cards {
    gap: 30px;
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .about-cards {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .about-card {
    max-width: 510px;
    margin-bottom: 20px;

  }
  .about-card img {
    height:400px;
    width:400px;
    
  }
}

@media (max-width: 480px) {
  .about-cards {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .about-card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    
  }
 .about-card h2{
  font-size: 24px;
 }
 .about-card p{
  font-size: 16px;
  margin-bottom: 10px;
 }
  .about-card img {
    height:auto;
    width:100%;
    
  }
}





/* Facility Section */
.facility-double-image-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  background: linear-gradient(109.5deg, rgb(19, 15, 226) 9.4%, rgb(14, 218, 211) 78.4%);
  flex-wrap: wrap;
  padding: 20px;
}

.facility-image-box {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.-image {
  width: 100%;
  height: 300px;
  /* border-radius: 10px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Responsive Facility Section */
@media (max-width: 768px) {
  .facility-double-image-section {
    flex-direction: column;
  }
}

/* Quality Section */
.quality-container {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  margin: 50px 30px 40px;
}

.quality-image img {
  width: 100%;
  max-width: 500px;
  /* border-radius: 10px; */
  
  height: 400px;
  /* padding: 20px; */
  background-color: white;
}

.quality-content {
  flex: 1;
}
.quality-content h2{
  text-align: center;
}
.quality-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px;
}

.quality-card {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

}
.quality-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(5, 84, 145, 0.3);
}

.quality-icon {
  width: 60px;
  height: 60px;
}

@media (max-width: 1024px) {
  .quality-container {
    flex-direction: column;
    gap: 30px; /* Less gap between image and content */
  }

  .quality-image img {
    max-width: 100%; /* Make image responsive to screen size */
  }

  .quality-content {
    width: 100%; /* Ensure content spans full width */
  }

  .quality-grid {
    gap: 15px; /* Reduce gap between feature cards */
  }

  .quality-card {
    padding: 12px;
    transition: transform 0.3s ease; /* Reduce padding for smaller screens */
  }
  .quality-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(5, 84, 145, 0.3);
  }

  .quality-icon {
    width: 50px;
    height: 50px; /* Adjust icon size for smaller screens */
  }
}

/* Mobile Design (max-width: 768px) */
@media (max-width: 768px) {
  .quality-container {
    flex-direction: column; /* Stack the image and content vertically */
    gap: 20px;
  }

  .quality-image img {
    max-width: 500px;
    margin:auto 100px; /* Ensure the image is responsive */
  }

  .quality-content {
    width: 100%; /* Content takes full width */
  }

  .quality-grid {
    gap: 10px; /* Reduce gap for mobile screens */
  }

  .quality-card {
    transition: transform 0.3s ease;
    padding: 10px; /* Smaller padding for mobile devices */
  }
  

  .quality-icon {
    width: 40px;
    height: 40px; /* Reduce icon size */
  }
}

/* Small Mobile Design (max-width: 480px) */
@media (max-width: 480px) {
  .quality-container {
   display: flex;
   flex-direction: column;
    gap: 15px; /* Tighten the gap for small mobile screens */
  }

  .quality-image img {
    max-width: 100%;
    margin: auto ;
    height: 300px; /* Ensure image takes full width */
  }

  .quality-content {
    width: 100%; /* Content takes full width */
  }

  .quality-grid {
    gap: 8px; /* Tighten the gap between cards */
  display: flex;
  flex-direction:column ;
  padding: 0%;
  margin: 10px auto ;
  }

  .quality-card {
    transition: transform 0.3s ease;
    padding: 20px; /* Further reduce padding for small screens */
  }
  

  .quality-icon {
    width: 35px;
    height: 35px; /* Even smaller icon size for tiny screens */
  }
}

/* New Section Styling */
.about-extra-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px ;
  padding: 20px;
  background: #2B4E84;
  
  color: white;
}

.extra-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extra-image {
  width: 100%;
  max-width: 500px;
  /* border-radius: 10px; */
  object-fit: cover;
  height: 350px;
}

.extra-description {
  flex: 1;
  padding: 20px;
}

.extra-description h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.extra-description li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .about-extra-section {
    flex-direction: column;
  }

  .extra-image {
    margin-bottom: 20px;
  }
}
