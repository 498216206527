

h1 {
  text-align: center;
  margin: 20px;
  
}

.services-content {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 70vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/ServicePage.avif');
  background-size: cover;
  background-position: center;
}

.services-content h1 {
  font-size: 3rem;
  color: white;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: "Righteous" ,sans-serif;
}

.menu-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.menu-item {
  position: relative;
  flex: 1 1 auto; /* Flexible width for items */
  max-width: 150px; /* Ensure items don't get too large */
  text-align: center;
}

.menu-item h2 {
  color: black;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

 .menu-item h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #1577da;
  transition: width 0.3s ease;
}

.menu-item h2:focus::before,
.menu-item h2:hover::before {
  width: 100%; /* Expands border from left to right */
 } 

 .menu-item h2:focus,
.menu-item h2:hover {
  color: #1577da;
} 

/* Dropdown menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 100;
  width: 200px;
  max-width: calc(100vw - 20px); /* Prevents the dropdown from exceeding the screen width */
  display: flex;
  flex-direction: column;
  overflow: auto; /* Handles overflow content */
}


.submenu-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.submenu-item:last-child {
  border-bottom: none;
}

.submenu-item:hover {
  background-color:#2B4E84 ;
  color: white;
}

.content-display {
  padding: 40px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
  height: auto;
  margin: 20px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  /* For tablets */
  .services-content {
    height: 50vh;
    padding: 20px;
  }

  .services-content h1 {
    font-size: 2.5rem;
  }

 

  .menu-item {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .menu-item h2 {
    font-size: 20px;
  }

  .content-display {
    padding: 20px;
    font-size: 14px;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  /* For mobile phones */
  .services-content {
    height: 40vh;
    padding: 10px;
  }

  .services-content h1 {
    font-size: 2rem;
  }

 
  .menu-item {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .menu-item h2 {
    font-size: 20px;
  }

  .content-display {
    /* padding: 15px; */
    font-size: 16px;
    margin: 10px;
  }
}
@media (hover: none) {
  .menu-item h2:hover::before,
  .menu-item h2:focus::before {
    width: 0; /* Disable hover effects on touch */
  }

  .menu-item h2:hover,
  .menu-item h2:focus {
    color: black; /* Keep default color */
  }
}
