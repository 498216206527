/* General Styles */
body {

  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 80%;
  margin: 2rem auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border:10px;
}

/* Company Details Section */
.company-details {
  background:#ffffff;
  color: #000000;
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  
}
.logo1{
height: 50px;
}
.company-details h2 {
  margin-bottom: 1rem;
}

.company-details p {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
}
.contact-link {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.contact-link  :hover{
  color:#2B4E84;
}


/* Contact Form Section */


/* Responsive Design */

/* Tablet (max-width: 768px) */
@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
    
  }

  .company-details {
    text-align: center;
    padding: 1.5rem;
  }

  .contact-form {
    padding: 1.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  input,
  textarea {
    padding: 0.6rem;
  }

  button {
    padding: 0.8rem;
    font-size: 0.9rem;
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .contact-wrapper {
    margin: 1rem;
    width: 90%;
  }

  .company-details {
    padding: 1rem;
  }

  .company-details h2 {
    font-size: 1.5rem;
  }

  .company-details p {
    font-size: 0.9rem;
  }

  
}

.contact-map {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.map-container {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

