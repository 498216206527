/* Base Styles */
.footer {
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.logo2{
height:50px;
width:90px;
}

/* Responsive styles for tablets and larger screens */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    text-align: left;
  }
}

.footer-links,
.footer-address {
  max-width: 300px;
}

.footer-links h4,
.footer-address h4 {
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
  color: #2B4E84;
  font-family: "Righteous" ,sans-serif;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li {
  margin: 8px 0;
}

.footer-links ul li a {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #0094f6;
}

.footer-address address {
  line-height: 1.6;
}

.footer-address a {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-address a:hover {
  color: #0094f6;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 14px;
  border-top: 1px solid #555;
  padding-top: 15px;
}

/* Mobile and Tablet Styles */
@media (max-width: 768px) {
  .footer {
    padding: 15px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    text-align: center;
  }

  .footer-links,
  .footer-address {
    max-width: 100%;
  }

  .footer-links h4,
  .footer-address h4 {
    font-size: 16px;
  }

  .footer-links ul li {
    margin: 6px 0;
  }

  .footer-bottom {
    margin-top: 15px;
    font-size: 12px;
    padding-top: 10px;
  }
}

/* Extra Small Devices (phones) */
@media (max-width: 480px) {
  .footer {
    padding: 10px;
  }

  .footer-content {
    gap: 20px;
  }

  .footer-links h4,
  .footer-address h4 {
    font-size: 14px;
  }

  .footer-bottom {
    font-size: 12px;
  }
}
