.features{
  background-color: #f4f4f4;
}

.features-container {
  display: flex;
  gap: 40px;
  align-items: center;
  
background-color: #2B4E84;
padding: 20px;
}

.features-image  {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  padding: 20px;
  
}



.features-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  padding: 40px;
  margin: 20px;
}


.feature-card {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color:white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  color: #000;

}
.feature-card:hover {
  transform: scale(1.05);
}



@media (max-width: 1024px) {
  .features-container {
    flex-direction: column;
    gap: 30px; /* Less gap between image and content */
  }

  .features-image iframe {
    max-width: 100%;
    margin-top: 0; /* Make image responsive to screen size */
  }

  .features-content {
    width: 100%; /* Ensure content spans full width */
  }

  .features-grid {
    gap: 15px; /* Reduce gap between feature cards */
  }

  .feature-card {
    padding: 12px;
    transition: transform 0.3s ease; /* Reduce padding for smaller screens */
  }
  .feature-card:hover {
    transform: scale(1.05);
  }

  .feature-icon {
    width: 50px;
    height: 50px; /* Adjust icon size for smaller screens */
  }
}

/* Mobile Design (max-width: 768px) */
@media (max-width: 768px) {
  .features-container {
    flex-direction: column; /* Stack the image and content vertically */
    gap: 20px;
  }

  .features-image iframe {
    max-width: 100%; /* Ensure the image is responsive */
  }

  .features-content {
    width: 100%; /* Content takes full width */
  }

  .features-grid {
    gap: 15px; /* Reduce gap for mobile screens */
  }

  .feature-card {
    transition: transform 0.3s ease;
    padding: 10px; /* Smaller padding for mobile devices */
  }
  .feature-card:hover {
    transform: scale(1.05);
  }

  .feature-icon {
    width: 40px;
    height: 40px; /* Reduce icon size */
  }
}

/* Small Mobile Design (max-width: 480px) */
@media (max-width: 480px) {
  .features-container {
    gap: 15px; /* Tighten the gap for small mobile screens */
  }

  .features-image iframe {
    max-width: 100%; /* Ensure image takes full width */
  }

  .features-content {
    width: 100%; /* Content takes full width */
  }

  .features-grid {
    gap: 8px; /* Tighten the gap between cards */
  padding: 0;
  }

  .feature-card {
    transition: transform 0.3s ease;
    padding: 10px; /* Further reduce padding for small screens */
  }
  .feature-card:hover {
    transform: scale(1.05);
  }

  .feature-icon {
    width: 35px;
    height: 35px; /* Even smaller icon size for tiny screens */
  }
}


.testimonials-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #A5CBF2;
  
  padding: 5px;
}

.testimonials-grid {
  display: flex;
  justify-content: center;
  
   /* Ensure cards wrap on smaller screens */
  gap: 20px;
  color: #f4f4f4;
  margin: 20px; 
}

.testimonial-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.feedback {
  font-style: italic;
  color: #ffffff;
  font-size: 16px;
}


.testimonial-card {

  width: 300px;
	background:#2B4E84;
	padding: 30px;
  margin: 10px;
	display: flex;
	flex-direction: column;
	border: 5px solid #fefefe;
	border-top: none;
	border-bottom: none;
	position: relative;
	&:before, 
	&:after {
		content: "";
		position: absolute;
		width: 50px;
		height: 36px;
		background-repeat: no-repeat;
	}
	&:before {		
		top: -10px;
		right: 10%;
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.5 101"><path fill="white" d="m80.817.554c.458-.025.805-.06,1.152-.06,19.188-.003,38.376-.002,57.564-.002.146,0,.291.011.437.011.491,0,.712.16.712.735.002,19.353.023,38.706.044,58.059.004,3.649-.176,7.285-.832,10.88-.796,4.361-2.397,8.442-4.643,12.241-.975,1.648-2.273,3.106-3.446,4.634-.443.577-.922,1.132-1.426,1.656-1.938,2.015-4.045,3.838-6.374,5.39-3.972,2.648-8.297,4.499-12.973,5.438-2.052.412-4.157.674-6.247.751-4.326.159-8.659.151-12.989.203-.286.003-.572-.07-.917-.115v-19.877c.267,0,.491,0,.714,0,3.833,0,7.667,0,11.5,0,3.384,0,6.55-.847,9.48-2.521,2.537-1.449,4.398-3.513,5.825-6.094,1.849-3.345,2.325-6.94,2.49-10.641.008-.186-.014-.374-.029-.56-.003-.037-.035-.071-.096-.186-.223,0-.488,0-.752,0-12.625,0-25.25,0-37.875,0-.208,0-.42-.027-.624.001-.519.072-.637-.184-.638-.649-.012-3.333-.06-6.666-.061-9.999-.005-15.395.002-30.791.004-46.186,0-1.017,0-2.034,0-3.111Z" /><path  fill="white" d="m10.837,80.498c.303,0,.528,0,.752,0,3.813,0,7.626,0,11.438,0,3.785,0,7.275-1.023,10.442-3.086,2.062-1.343,3.688-3.097,4.776-5.331.269-.552.664-1.042.952-1.586.16-.303.213-.663.323-.994.875-2.647,1.304-5.368,1.354-8.152.003-.187-.008-.375-.019-.562-.003-.06-.027-.119-.059-.254-.244-.011-.505-.034-.765-.034-12.605-.002-25.211-.001-37.816-.002-1.322,0-1.338,0-1.339-1.309-.008-16.417-.011-32.833-.014-49.25,0-2.917.01-5.833-.01-8.75-.003-.473.123-.708.617-.686.249.011.5-.01.75-.01,19.023,0,38.045,0,57.068,0,1.348,0,1.361,0,1.362,1.331.018,19.48.039,38.959.042,58.439,0,4.404-.376,8.765-1.546,13.04-.769,2.809-1.905,5.456-3.306,7.988-.679,1.227-1.481,2.395-2.32,3.52-1.017,1.364-2.061,2.721-3.223,3.96-1.867,1.992-3.953,3.752-6.226,5.276-4.003,2.682-8.364,4.559-13.084,5.497-2.174.432-4.407.686-6.623.766-4.348.158-8.702.133-13.053.197-.476.007-.478-.262-.48-.6-.013-2.166-.05-4.333-.046-6.499.006-4.125.034-8.25.053-12.375,0-.144,0-.288,0-.534Z" /></svg>');		
	}
	&:after {
		bottom: -10px;
		left: 30%;		
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.5 102"><path fill="white" d="m21.67,40.996c.335,0,.603,0,.871,0,12.729,0,25.459,0,38.188,0,.921,0,.926.004.926.899.015,18.541.031,37.081.042,55.622,0,.979-.041,1.958-.042,2.937,0,.396-.145.583-.554.545-.186-.017-.375-.002-.562-.002-19.271,0-38.542,0-57.813,0-.867,0-.877,0-.887-.881-.026-2.354-.06-4.707-.06-7.061-.005-17.395-.003-34.79-.002-52.185,0-3.335.114-6.66.825-9.937.508-2.34,1.145-4.638,2.073-6.849.447-1.066.846-2.16,1.395-3.173.648-1.195,1.37-2.364,2.178-3.457,1.149-1.555,2.324-3.106,3.633-4.524,2.381-2.579,5.085-4.787,8.126-6.57,4.232-2.481,8.771-4.108,13.623-4.789,2.201-.309,4.432-.497,6.654-.554,3.498-.09,6.999-.025,10.5-.023.862,0,.87.007.87.837,0,6.187-.009,12.374.003,18.56,0,.484-.177.598-.621.597-3.958-.009-7.917-.039-11.875.017-1.828.026-3.624.311-5.406.83-2.391.695-4.464,1.843-6.339,3.468-2.378,2.061-3.955,4.581-4.83,7.588-.729,2.504-1.009,5.052-.945,7.649.003.121.016.242.03.457Z" /><path fill="white" d="m101.501,41.049h40.222c0,1.057,0,2.01,0,2.963,0,17.791.003,35.581,0,53.372,0,1-.046,1.999-.039,2.999.003.449-.137.67-.611.617-.185-.021-.375-.002-.562-.002-19.167,0-38.333,0-57.5-.001-1.292,0-1.124.136-1.14-1.139-.036-2.77-.055-5.541-.056-8.311-.005-16.582-.009-33.165.005-49.747.002-2.29-.017-4.592.201-6.866.192-1.998.61-3.989,1.102-5.939.476-1.885,1.095-3.744,1.801-5.556.471-1.209,1.203-2.317,1.819-3.47.059-.11.129-.214.19-.323,2.575-4.597,6.106-8.327,10.329-11.44,5.251-3.871,11.178-5.995,17.603-6.812,4.082-.52,8.19-.405,12.293-.4,1.292.002,2.584.029,3.875-.004.512-.013.636.176.636.647,0,2.75.038,5.499.036,8.249-.002,3.458-.042,6.916-.036,10.374,0,.568-.158.747-.741.743-3.667-.025-7.333-.017-11-.011-2.28.004-4.493.331-6.691,1.012-2.162.67-3.995,1.786-5.707,3.217-3.207,2.68-4.893,6.169-5.496,10.22-.268,1.8-.35,3.627-.533,5.609Z" /></svg>');		
	}
	* {
		text-align: center;
	}
	h1 {
		text-transform: uppercase;
		line-height: 1.25;
		font-size: 22px;
		font-family: "Raleway", sans-serif;
		letter-spacing: 2px;
	}
	p {
		font-size: 16px;
		line-height: 18px;
		font-family: "Monserrat", sans-serif;
		&.source {
			font-style: italic;
		}
	}
	.border {
		height: 5px;
		width: 100%;
		position: absolute;
		left: 0;
		&.top {
			top: 0;
			&:before,
			&:after {
				content: "";
				position: absolute;
				background: #fff;
				z-index: 10;
				height: 5px;
			}
			&:before {
				width: 75%;
				left: 0;							
			}
			&:after {
				width: 5%;
				right: 0;
			}
		}
		&.bottom {
			bottom: 0;
			&:before,
			&:after {
				content: "";
				position: absolute;
				background: #fff;
				z-index: 10;
				height: 5px;
			}
			&:before {
				width: 25%;
				left: 0;							
			}
			&:after {
				width: 55%;
				right: 0;
			}
		}
	}
	.shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before,
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			background: #0385be;
		}
		&:before {
			right: 0;
			transform-origin: top right;
			transform: rotate(-45deg);
			width: 75%;
			height: 250%;
		}
		&:after {
			left: 0;
			transform-origin: bottom left;
			transform: rotate(45deg);
			width: 100%;
			height: 100%;
		}
	}
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-grid {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center-align the stacked cards */
  }

  .testimonial-card {
    padding: 15px;
  }

  .testimonial-card h1 {
    font-size: 3rem; /* Reduce font size for headings */
  }

  .testimonial-card p {
    font-size: 14px; /* Adjust paragraph font size */
  }
}

@media (max-width: 480px) {
  .testimonials-grid {
    gap: 10px; /* Reduce spacing between cards */
    width: 90%;
  }

  .testimonial-card {
    padding: 10px;
  }

  .testimonial-card h1 {
    font-size: 2.5rem; /* Further reduce heading size */
  }

  .testimonial-card p {
    font-size: 12px; /* Further adjust paragraph font size */
  }

  .testimonial-card:before,
  .testimonial-card:after {
    width: 20px;
    height: 24px; /* Scale down decorative images */
  }
}

.image-slider-container {
  width: 100%;
  overflow: hidden; /* Hides the overflow */
  position: relative;
  height: auto; /* Set the height for the slider */
  padding: 20px;
}

.image-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  width: 100%; /* Ensures the slider adapts to the container */
}

.image-slider:hover {
  animation: slide 5s linear infinite; /* Slides all images to the left on hover */
}

.image-slider img {
  width: 33.33%; /* Each image takes up 1/3 of the slider */
  flex-shrink: 0; /* Prevent images from shrinking */
  height: 100%; /* Maintain slider height */
  object-fit: cover; /* Ensure images cover the space */
  border-right: 2px solid #fff;
  padding: 10px; /* Optional: Add spacing effect */
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Shift the entire slider */
  }
}

/* Responsive design for tablets */
@media (max-width: 768px) {
  .image-slider img {
    width: 50%; /* Each image takes up half the viewport width */
  }

  .image-slider {
    animation: slide-tab 10s linear infinite;
  }
}

@keyframes slide-tab {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% * 5)); /* Slide based on the number of images */
  }
}
/* Responsive design for mobile phones */
@media (max-width: 480px) {
  .image-slider img {
    width: 100%; /* Each image takes up the full view width */
  }

  .image-slider {
    animation: slide-mobile 10s linear infinite;
  }
}

@keyframes slide-mobile {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-550vw); /* Slide the entire viewport width */
  }
}