/* General Styles */
.navbar {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  color: #000000;
}

.logo1 {
  height: 70px;
  width: 150px;
  color: #ffffff;
  margin-left:10px;
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.nav-links a {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  position: relative;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  border-radius: 20px; /* Make it circular */
  display: inline-block;
  font-family: "Righteous" ,sans-serif;
}

/* Add a circular background effect on hover */
.nav-links a:hover,
.nav-links a:active {
  background-color: #2B4E84; /* Circle background color */
  color: #A5CBF2; /* Change text color on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

/* Hamburger Menu Icon */
.hamburger {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

/* Responsive Styles for Tablets and Mobile */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hidden by default */
    flex-direction: column;
    background-color: #fffefe; /* Dropdown background color */
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%; /* Full-width dropdown */
    padding: 1rem;
    border-top: 1px solid #ddd;
    gap: 1rem;
    z-index: 1000; /* Ensure it appears above other content */
  }

  .nav-links.active {
    display: flex; /* Display the menu when active */
  }

  .nav-links a {
    color: #010101; /* Adjust link color for dropdown */
    background-color: transparent;
  }

 

  .hamburger {
    display: block;
  }

  .navbar {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px 20px 10px 0;
  }
}
