/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Hero Section */
  .hero {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 4rem 2rem; */
    background-color: #f4f4f4;
    
    text-align: center;
    
  }
  
  .hero-content {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height:70vh;
    background:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../assets/homepage.jpg");
    background-size: cover;
    background-position: center;
  }

  
  .hero-content h1 {
    font-size: 3rem;
    color: white;
    align-items:flex-end;
    justify-content: flex-end;
    font-family: 'Righteous';
  }
  
  .hero-content p {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 2rem;
    font-family: "Righteous" ,sans-serif;
  }

  

  
  @media (max-width: 768px) {
    
  
    .hero-content {
      height: 50vh;
      width: 100%;
    }
  
    .hero-content h1 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  
    .btn-primary {
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
   
    
    .hero-content {
      height: 40vh;
      margin-bottom: 15px;
      background-size: cover; /* Make sure the image covers the area */
      background-position: center;
    }
  
    .hero-content h1 {
      font-size: 1.8rem;
    }
  
    .hero-content p {
      font-size: 14px;
      margin-bottom: 1rem;
    }
  
    .btn-primary {
      padding: 0.5rem 0.8rem;
      font-size: 0.9rem;
    }
  }
  
  /* Cards Section */
  .cards-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 40px;
    width: 100%;
    max-width: 1200px;
    
  }
  
  .card {
    background-color: #2B4E84;
   
    /* padding: 2rem; */
    text-align: center;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
    margin-bottom: 70px;
    margin-top: 20px;
    width: 300px;
    height:auto;
    border-radius: 10px;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(9, 122, 244, 0.5);
  }
  
  .card-icon {
    width: 300px;
    height: 300px;
    margin-bottom: 1rem;
    object-fit: cover;
    
  }
  
  .card h3 {
    font-size: 1.5rem;
    color: #ffffff;
  }
  
  .card p {
    font-size: 1rem;
    color: #ffffff;
    padding: 10px;
  }
  
  /* Responsive Design */
@media (max-width: 1024px) {
  .cards-section {
    gap: 1.5rem;
  }

  .card {
    padding: 1.5rem;
    margin-bottom: 40px;
  }

  .card h3 {
    font-size: 1.3rem;
  }

  .card p {
    font-size: 0.95rem;
  }

  .card-icon {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 768px) {
  .cards-section {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 5px;
   justify-items: center;
  }

  .card {
    padding: 1.2rem;
    margin-bottom: 30px;
    width:500px;
    
  }

  .card h3 {
    font-size: 1.2rem;
  }

  .card p {
    font-size: 0.9rem;
  }

  .card-icon {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .cards-section {
    grid-template-columns:repeat(1, 1fr);
    gap: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
  }

  .card {
    padding: 1rem;
    width: 100%;
    margin:20px 20px 20px 20px;
  }

  .card h3 {
    font-size: 14px;
  }

  .card p {
    font-size: 14px;
  }

  .card-icon {
    width: 100%;
    height: 100%;
  }
}