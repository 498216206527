.resources-content {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 70vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/productspage.jpg');
  background-size: cover;
  background-position: center;
}

.resources-content h1 {
  font-size: 3rem;
  color: white;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: "Righteous" ,sans-serif;
}

.content-display1 {
  padding: 40px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
  height: auto;
  margin: 20px;
  text-decoration: none;
  
}

.content-display1 a {
  text-decoration: none;
  line-height: 1.5;
}

.menu-item1 {
  position: relative;
  flex: 1 1 auto; /* Flexible width for items */
  max-width: 150px; /* Ensure items don't get too large */
  text-align: center;
}

.menu-item1 h2 {
  color: black;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.menu-item1 h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #1577da;
  transition: width 0.3s ease;
}

.menu-item1 h2:focus::before,
.menu-item1 h2:hover::before {
  width: 100%; /* Expands border from left to right */
}

.menu-item1 h2:focus,
.menu-item1 h2:hover {
  color: #1577da;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  /* For tablets */
  .resources-content {
    height: 50vh;
    padding: 20px;
  }

  .resources-content h1 {
    font-size: 2.5rem;
    text-align: center; /* Center align for smaller screens */
  }

  .content-display1 {
    padding: 30px;
    font-size: 14px;
    margin: 15px;
  }
}

@media screen and (max-width: 480px) {
  /* For mobile phones */
  .resources-content {
    height: 40vh;
    padding: 10px;
  }

  .resources-content h1 {
    font-size: 2rem;
    text-align: center;
  }

  .content-display1 {
    padding: 20px;
    font-size: 16px;
    margin: 10px;
  }
  .menu-item1 h2{
    font-size: 20px;
  }
}
@media (hover: none) {
  .menu-item h2:hover::before,
  .menu-item h2:focus::before {
    width: 0; /* Disable hover effects on touch */
  }

  .menu-item h2:hover,
  .menu-item h2:focus {
    color: black; /* Keep default color */
  }
}