.calculator-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px;
    gap: 20px;
  }
  
  .calculator-left {
    flex: 1;
    max-width: 50%;
  }
  
  .calculator-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .calculator-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .calculator-left h1 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .result {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #28a745;
  }
  